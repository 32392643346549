import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'

import ButtonSelector from '../../components/ButtonSelector'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const useStyles = (props) =>
    makeStyles((theme) => ({
        container: {
            marginTop: '-150px',
            zIndex: '2',
            paddingTop: '30px',
            background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.048) 0%, rgba(0, 0, 0, 0) 100%), rgba(15, 5, 24, 0.5)',
            [theme.breakpoints.down('sm')]: {
                marginTop: '-58px',
                paddingTop: '4px',
            },
        },
        artDirected: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('md')]: {
                width: '820px',
                height: '315px',
            },
            [theme.breakpoints.down('sm')]: {
                width: '560px',
                height: '315px',
            },
        },
        titles: {
            textAlign: 'center',
            color: '#ffffff',
            paddingBottom: '10px',
            fontWeight: '700',
            fontSize: '1.125rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                fontWeight: '400',
            },
        },
        subtitles: {
            textAlign: 'center',
            color: '#ffffff',
            fontSize: '2.25rem',
            fontWeight: '700',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
            },
        },
        textBody: {
            marginTop: '5rem',
            [theme.breakpoints.down('sm')]: {
                marginTop: '3rem',
            },
        },
        mainTitle: {
            fontSize: '3.375rem',
            fontWeight: '700',
            color: '#0F0518',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        text: {
            fontSize: '1.5rem',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        buttonPrimary: {
            width: '206px',
            height: '72px',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            lineHeight: '22px',
            textAlign: 'center',
            margin: '50px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        buttonSecondary: {
            width: '206px',
            height: '72px',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            lineHeight: '22px',
            textAlign: 'center',
            margin: '50px 0px',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
    }))

const HeroImageInfo = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasButtons = Boolean(data.buttons)
    const hasOverlay = Boolean(
        data.duration && data.numberOfLessons && data.age && data.price
    )
    const hasImages = Boolean(data.image && data.mobileImage)

    const images =
        hasImages &&
        withArtDirection(getImage(data.image), [
            {
                media: '(max-width: 600px)',
                image: getImage(data.mobileImage),
            },
        ])

    return (
        <Box pb={5}>
            <Grid
                container
                style={{
                    marginTop:
                        hasImages || (!hasImages && !hasOverlay)
                            ? '0px'
                            : '87px',
                }}
            >
                {hasImages && (
                    <GatsbyImage
                        className={classes.artDirected}
                        image={images}
                        alt={data.image.title}
                    />
                )}
                {hasOverlay && (
                    <Grid container item className={classes.container}>
                        <Grid item xs={3}>
                            <Typography className={classes.titles}>
                                Duration:
                            </Typography>
                            <Typography className={classes.subtitles}>
                                {data.duration}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.titles}>
                                Lessons:
                            </Typography>
                            <Typography className={classes.subtitles}>
                                {data.numberOfLessons}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.titles}>
                                Age:
                            </Typography>
                            <Typography className={classes.subtitles}>
                                {data.age}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.titles}>
                                Price:
                            </Typography>
                            <Typography className={classes.subtitles}>
                                £{data.price}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Container maxWidth="lg" className={classes.textBody}>
                <Typography className={classes.mainTitle}>
                    {data.title}
                </Typography>
                <Typography className={classes.text}>
                    {renderRichText(data.text, options())}
                </Typography>
                <Grid container alignItems="flex-start">
                    {!hasButtons && <Box py={3}></Box>}
                    {hasButtons &&
                        data.buttons.map((button) => {
                            const buttonColor = (color) => {
                                if (color === 'Orange') {
                                    return classes.buttonPrimary
                                }
                                return classes.buttonSecondary
                            }
                            const theButtonColor = buttonColor(
                                button.buttonColor
                            )
                            const hasTagClass = button.tagClass
                                ? button.tagClass
                                : ''
                            return (
                                <Grid item xs={6} md={3}>
                                    <ButtonSelector
                                        className={`${theButtonColor} ${hasTagClass}`}
                                        data={button}
                                    >
                                        {button.label}
                                    </ButtonSelector>
                                </Grid>
                            )
                        })}
                </Grid>
            </Container>
        </Box>
    )
}
export default HeroImageInfo
